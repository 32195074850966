<template>
  <modal title="Aftalebrev" :showBackBtn="true" @back="onBackInContractModal" @close="onCloseContractModal" :show="true"
    :showActions="true">

    <contract v-if="letterData" :letterData="letterData" :is-old="!customerLetter.is_current"
      :customer-department="customer ? customer.customer_department : null"></contract>

    <div slot="actions" v-if="letterData" class="agreement-letter-actions actions-full-width">
      <button v-if="letterData && letterData.version === '2.0'" @click="print" class="btn btn-lg btn--print">
        Print
      </button>

      <button @click="approveLetter(customerLetter)" :disabled="customerLetter.approved || !customerLetter.is_current || approveLoading
        " class="btn btn--positive btn--aprrove" :class="{ loading: approveLoading }" v-if="!customerLetter.approved">
        Godkend
      </button>

      <p v-if="customerLetter.approved">
        Du har godkendt dette aftalebrev
        <span v-if="customerLetter.approved_at">d.
          {{ moment(customerLetter.approved_at).format("DD-MM-YYYY HH:mm") }}</span>.
      </p>
    </div>
  </modal>
</template>

<script>
import Modal from "../modal/base-modal.vue";
import DataStore from "../../services/datastore";
import Moment from "moment";
import Contract from "./contract.vue";

export default {
  props: {
    order: { type: Object, default: null },
    customer: { type: Object, default: null },
    customerLetter: {
      type: Object, default() {
        return {}
      }
    }
  },
  data() {
    return {
      moment: Moment,
      approveLoading: false
    };
  },
  methods: {
    print() {
      if (this.letterData && this.letterData.letterstring) {
        let printWindow = window.open("/contract-print.html");
        printWindow.LETTER_MARKUP = this.letterData.letterstring;
        printWindow.LETTER_APPROVAL_DATE = (this.agreementAproovalDateFormatted) ? `Aftalebrev godkendt d. ${this.agreementAproovalDateFormatted}` : '';
      }
    },
    approveLetter(letter) {
      if (this.approveLoading) return;

      this.approveLoading = true;
      DataStore.approveAftaleBrev(this.order.main_order_hash, letter.id)
        .then((res) => {
          letter.approved = true;
          letter.approved_at = Moment();
          this.approveLoading = false;
          this.$emit('approvedletter');
        })
        .catch((err) => {
          console.error(err);
          this.approveLoading = false;
        });
    },
    onCloseContractModal() {
      this.$emit("close");
    },
    onBackInContractModal() {
      this.$emit('back');
    }
  },
  computed: {
    letterData() {
      return this.customerLetter ? this.customerLetter.letter_data : null;
    },
    agreementAproovalDateFormatted() {
      return this.customerLetter.approved_at ? Moment(this.customerLetter.approved_at).format('DD-MM-YYYY HH:MM') : '';
    }
  },
  components: {
    Modal,
    Contract,
  },
};
</script>